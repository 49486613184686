import {
  Avatar,
  Button,
  CopySVG,
  DotGridSVG,
  ExitSVG,
  Input,
  MagnifyingGlassSVG,
  Profile,
  Tag,
  Typography,
  mq,
} from '@ensdomains/thorin';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getWalletAddress,
  checkConnection,
  getContractInstance,
  getNetworkID,
  isMetaMaskInstalled,
} from '../../common';
import { ethers } from 'ethers';
import styled, { css } from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import MetaMaskOnboarding from '@metamask/onboarding'
import edexaLogo from '../../assets/edexa.svg';


const RouteContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-gap: ${theme.space['1']};
    gap: ${theme.space['1']};
    transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
    transform: translateX(0%);
    opacity: 1;
    font-size: 1rem;
    font-weight: 700;
    white-space: nowrap;

    ${mq.lg.min(css`
      gap: ${theme.space['6']};
      position: relative;
    `)}
  `
);

const LinkWrapper = styled.a(
  ({ theme }) => css`
    --indicator-color: ${theme.colors.accent};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.space['1.5']};
    cursor: pointer;

    color: ${theme.colors.greyPrimary};
    transition: all 0.15s ease-in-out;

    &::after {
      height: ${theme.space['2']};
      width: ${theme.space['2']};
      border: none;
      top: ${theme.space['0.5']};
      right: ${theme.space['0.5']};
    }
  `
);

const StyledAnchor = styled.div(
  ({ theme }) => css`
    white-space: nowrap;
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.body};
  `
);

const IconContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.space['4']};
    height: ${theme.space['4']};
  `
);

const InputHeader = styled.div(
  () => css`
    display: flex;
    gap: 20px;
  `
);

const SearchInputWrapper = styled.div(
  ({ theme }) => css`
    z-index: 1;
    box-shadow: ${theme.boxShadows['0.02']};
    border-radius: ${theme.radii['3xLarge']};
    border-color: ${theme.colors.border};
    width: 100%;
    & input::placeholder {
      color: ${theme.colors.greyPrimary};
      font-weight: ${theme.fontWeights.bold};
    }
  `
);

const SearchResultsContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    margin-top: 4rem;
    width: 17%;
    box-shadow: 0 2px 12px ${theme.colors.border};
    border-radius: ${theme.radii.extraLarge};
    border: ${theme.borderWidths.px} ${theme.borderStyles.solid}
      ${theme.colors.border};
    &[data-error='true'] {
      border-color: ${theme.colors.red};
    }
    overflow: hidden;
    z-index: 1000;
    transform: translateY(-${theme.space['2']});
    transition: 0.35s all cubic-bezier(1, 0, 0.22, 1.6),
      0s border-color linear 0s, 0s width linear 0s;
    cursor: pointer;
  `
);

const SearchItemContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.space['2']};
    height: ${theme.space['14']};
    padding: 0 ${theme.space['4']};
    border-bottom: ${theme.borderWidths['0.375']} ${theme.borderStyles.solid}
      ${theme.colors.border};
    &:last-of-type {
      border-bottom: 0;
    }
    position: relative;
    opacity: 0.9;
    background-color: white;
  `
);

const LeadingSearchItem = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: min-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: ${theme.space['4.5']};
    flex-gap: ${theme.space['4.5']};
  `
);

const AvatarWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.space['8']};
    min-width: ${theme.space['8']};
    height: ${theme.space['8']};
    flex-grow: 1;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      filter: grayscale(100%);
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }
  `
);

const AddressAndName = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `
);

const StyledTag = styled(Tag)(
  () => css`
    width: max-content;
    justify-self: flex-end;
    overflow-wrap: normal;
    word-break: keep-all;
    white-space: nowrap;
  `
);

const AddressTag = styled(StyledTag)(
  ({ theme }) => css`
    border: ${theme.borderWidths['0.375']} solid ${theme.colors.border};
    background-color: transparent;
  `
);

const MagnifyingGlassIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['4']};
    height: ${theme.space['4']};
  `
);

const StyledInputParent = (size: 'medium' | 'extraLarge') =>
  css(
    ({ theme }) => css`
      border-radius: ${theme.radii.full};
      background-color: ${theme.colors.backgroundSecondary};
      transition: background-color 0.35s ease-in-out;
      ${size === 'medium' &&
      css`
        & > div {
          border-radius: ${theme.radii.full};
          input {
            padding-left: ${theme.space['12']};
          }
        }
      `}
      &:focus-within {
        background-color: ${theme.colors.backgroundPrimary};
        & input::placeholder {
          color: transparent;
        }
      }
    `
  );

const DOMAINS_QUERY = gql`
  query {
    wrappedDomains {
      name
      owner {
        id
      }
    }
  }
`;

const Header = () => {
  const [name, setName] = useState<string>('');
  const [disconnectClicked, setDisconnectClicked] = useState(
    localStorage.getItem('disconnectClicked') === 'true'
  );
  const [walletAddress, setWalletAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [ensName, setENSName] = useState('');
  const [chainId,setChainId]=useState(0)

  const navigate = useNavigate();
  const location = useLocation();


  async function ID(){
    const chainid= await getNetworkID()
    setChainId(chainid);
  }
  
  ID();
  const connectWallet = async () => {
    try {
      if (localStorage.getItem('disconnectClicked')) {
        await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
      }

      await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      setDisconnectClicked(false);
      localStorage.setItem('disconnectClicked', 'false');
      window.location.reload();
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  async function disconnect() {
    // console.log('please disconnect account  from meatmask manually');
    try {
      localStorage.setItem('disconnectClicked', 'true');
      setDisconnectClicked(true);
    } catch (error) {
      console.error('Error disconnecting from wallet:', error);
    }
  }

  
  useEffect(() => {
    const handleAccountsChanged = async () => {
      fetchData();
      window.location.reload();
    };
  
    const handleChainChanged = () => {
      fetchData();
      window.location.reload();
      console.log('Chain changed to:', parseInt(window.ethereum.chainId));
    };
    
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.off('accountsChanged', handleAccountsChanged);
        window.ethereum.off('chainChanged', handleChainChanged);
      }
    };
  });

  const fetchData = async () => {
    if (await checkConnection()) {
      try {
        const networkID = await getNetworkID();
        // console.log('chainID:', networkID);
        const wall = await getWalletAddress();
        setWalletAddress(wall);
        setIsConnected(true);
        const { resolverContract } = await getContractInstance();
        const reverseName = `${wall.slice(2)}.addr.reverse`;
        const node = ethers.utils.namehash(reverseName);
        if (networkID === 1995) {
          const ensName_ = await resolverContract.name(node);
          setENSName(ensName_);
         
        } else {
          setENSName('');
          navigate('/unsupportedNetwork');
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('please connect wallet');
      setIsConnected(false);
    }
  };
  useEffect(() => {
    fetchData();
    const intervalId = window.setInterval(() => {
      fetchData();
    }, 1000);
  
    return () => window.clearInterval(intervalId);
  }, []);

  const handleDomainNameChange = (e: { target: { value: string } }) => {
    const inputValue = e?.target?.value?.replace(/[^\w]/g, '').toLowerCase();
    setName(inputValue);
  };

  const { data } = useQuery(DOMAINS_QUERY);

  const dropdownItems: string[] = [];

  dropdownItems.push(
    data?.wrappedDomains
      .map((domain: any) => domain?.name)
      .filter((item: string) => item === `${name}.edx`)
  );

  const SearchResultsElement = (
    <SearchResultsContainer
      style={{
        width: 'undefined',
      }}
      data-testid="search-input-results"
    >
      {dropdownItems.map((searchItem: any, index: any) => {
        return (
          <SearchItemContainer
            key={index}
            data-testid="search-result-address"
            onClick={
              name?.length < 3
                ? undefined
                : searchItem?.[0] === undefined
                ? () => navigate(`/${name}.edx/register`)
                : () => navigate(`/${searchItem?.[index]}/profile`)
            }
            onMouseDown={(e) => e.preventDefault()}
          >
            <LeadingSearchItem>
              <AvatarWrapper>
                <Avatar label="avatar" />
              </AvatarWrapper>
              <AddressAndName>
                <Typography weight="bold">
                  {searchItem?.[0] || `${name}.edx`}
                </Typography>
              </AddressAndName>
            </LeadingSearchItem>
            <AddressTag>
              {name?.length >= 3
                ? searchItem?.[0]
                  ? 'Registered'
                  : 'Available'
                : 'Too Short'}
            </AddressTag>
          </SearchItemContainer>
        );
      })}
    </SearchResultsContainer>
  );

  useEffect(() => {
    setName('');
  }, [location.pathname]);

  return (
    <header id="header">
      <div className="header-main">
        {location?.pathname === '/' ? (
          <img
            // src="https://edexa-general.s3.ap-south-1.amazonaws.com/logo.svg"
            src={edexaLogo}
            alt="edeXa Logo"
            loading="lazy"
            onClick={()=>chainId==1995&&chainId?navigate('/'): null}
            style={{ cursor: 'pointer' }}
           
          />
        ) : (
          <InputHeader>
            <img
              // src="https://edexa-general.s3.ap-south-1.amazonaws.com/XLogo.png"
              src={edexaLogo}
              alt="edeXa Logo"
              loading="lazy"
              onClick={()=>chainId==1995&&chainId?navigate('/'): null}
              style={{ cursor: 'pointer' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SearchInputWrapper>
                <Input
                  clearable
                  hideLabel
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  label="Domain Name"
                  placeholder="Search for a name"
                  size="medium"
                  parentStyles={StyledInputParent('medium')}
                  value={name}
                  icon={<MagnifyingGlassIcon as={MagnifyingGlassSVG} />}
                  onChange={handleDomainNameChange}
                  disabled={!chainId||chainId!=1995}
                />
              </SearchInputWrapper>
              {name && SearchResultsElement}
            </div>
          </InputHeader>
        )}
        <div className="wallet-button">
          {!isMetaMaskInstalled() ? (
            <Button
              shape="rounded"
              width="45"
              onClick={() => {
                const onboarding = new MetaMaskOnboarding();
                onboarding.startOnboarding();
              }}
            >
              Install MetaMask
            </Button> 
          ) : !disconnectClicked && isConnected ? (
            <>
              <RouteContainer>
                <LinkWrapper onClick={()=>chainId==1995&&chainId?navigate('/my/names'): null }>
                  <IconContainer
                    as={DotGridSVG}
                    data-testid="route-item-icon"
                  />
                  <StyledAnchor data-testid="route-item-text">
                    My Names
                  </StyledAnchor>
                </LinkWrapper>
              </RouteContainer>
              <Profile
                address={`${walletAddress.slice(0, 5)}...${walletAddress.slice(
                  -5
                )}`}
                ensName={ensName}
                dropdownItems={[
                  {
                    label: `${walletAddress.slice(
                      0,
                      5
                    )}...${walletAddress.slice(-5)}`,
                    onClick: () => {
                      navigator.clipboard.writeText(`${walletAddress}`);
                    },
                    icon: <CopySVG />,
                  },
                  {
                    label: 'Disconnect',
                    onClick: () => {
                      disconnect();
                    },
                    color: 'red',
                    icon: <ExitSVG />,
                  },
                ]}
              />
            </>
          ) : (
            <Button shape="rounded" width="45" onClick={connectWallet}>
              Connect Wallet
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
