  import { gql, useQuery } from '@apollo/client';
  import {
    Card,
    Heading,
    Input,
    ListDownSVG,
    ListUpSVG,
    MagnifyingGlassSimpleSVG,
    Select,
    Spinner,
    Tag,
    Typography,
    mq,
  } from '@ensdomains/thorin';
  import { useEffect, useState } from 'react';
  import { useNavigate } from 'react-router-dom';
  import styled, { css } from 'styled-components';
  import {
    colors,
    containsTwoDots,
    generateRandomGradient,
    getWalletAddress,
  } from '../common';

  const StyledCard = styled(Card)(
    ({ theme }) => css`
      max-width: 780px;
      margin: 0 auto;
      flex-direction: column;
      gap: ${theme.space['4']};
      padding: 0;
    `
  );

  const TabWrapper = styled.div(
    ({ theme }) => css`
      background-color: ${theme.colors.backgroundPrimary};
      border-radius: ${theme.radii['2xLarge']};
    `
  );

  const TabWrapperWithButtons = styled(TabWrapper)(
    ({ theme }) => css`
      display: flex;
      flex-direction: column;
      align-items: normal;
      justify-content: flex-start;
      width: 100%;
      max-width: 100%;
      background: ${theme.colors.backgroundPrimary};
    `
  );

  const Footer = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${theme.space['8']};
      border-top: 1px solid ${theme.colors.border};
    `
  );

  const TableHeader = styled.div(
    ({ theme }) => css`
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      border-bottom: 1px solid ${theme.colors.border};
      padding: ${theme.space['3']} ${theme.space['4']};
      gap: ${theme.space['2']};
      ${mq.sm.min(css`
        flex-direction: row;
        align-items: center;
        padding: ${theme.space['3']} ${theme.space['4.5']};
        gap: ${theme.space['6']};
      `)}
    `
  );

  const TableHeaderLeading = styled.div(
    () => css`
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `
  );

  const TableHeaderLeadingLeft = styled.div(
    ({ theme }) => css`
      display: flex;
      gap: ${theme.space['2']};
      align-items: center;
      color: ${theme.colors.text};
      ${mq.sm.min(css`
        gap: ${theme.space['4']};
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
      `)}
    `
  );

  const TableHeaderTrailing = styled.div(
    ({ theme }) => css`
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      ${mq.sm.min(css`
        flex: '2';
        width: ${theme.space['32']};
      `)}
    `
  );

  const TableHeaderLeftControlsContainer = styled.div(
    ({ theme }) => css`
      display: flex;
      gap: ${theme.space['2']};
      align-items: center;
      flex: 1;
    `
  );

  const DirectionButton = styled.button(
    ({ theme }) => css`
      transition: all 0.15s ease-in-out;
      width: ${theme.space['10']};
      flex: 0 0 ${theme.space['10']};
      height: ${theme.space['10']};
      border: 1px solid ${theme.colors.border};
      border-radius: ${theme.space['2']};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        display: block;
        width: ${theme.space['3']};
        height: ${theme.space['3']};
        path {
          fill: ${theme.colors.accent};
        }
      }
      &:hover {
        background-color: ${theme.colors.border};
      }
    `
  );

  const NameItemWrapper = styled.div(
    ({ theme }) => css`
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      padding: ${theme.space['3']} ${theme.space['4']};
      gap: ${theme.space['2']};
      border-bottom: 1px solid ${theme.colors.border};
      transition: all 0.15s ease-in-out, border 0s;
      background: ${theme.colors.backgroundPrimary};
      cursor: pointer;
      &:hover {
        background: ${theme.colors.backgroundSecondary};
      }
      &:last-of-type {
        border: none;
      }
      ${mq.sm.min(css`
        padding: ${theme.space['3']} ${theme.space['4.5']};
        gap: ${theme.space['4']};
      `)}
    `
  );

  const NameItemContainer = styled.div(
    ({ theme }) => css`
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      min-width: 0;
      gap: ${theme.space['2']};
      flex-gap: 16px;
    `
  );

  const AvatarLogo = styled.div(
    ({ gradient }: any) => css`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid transparent;
      background: ${gradient};
    `
  );

  const NameItemContent = styled.div(
    () => css`
      flex: 1;
      display: flex;
      position: relative;
      flex-direction: column;
      overflow: hidden;
      min-width: 0;
      width: 0;
    `
  );

  const Container = styled.div(
    ({ theme }) => css`
      display: flex;
      justify-content: flex-start;
      font-weight: ${theme.fontWeights.bold};
      line-height: 1.36;
      overflow: hidden;
    `
  );

  const Name = styled.span(
    ({ theme }) => css`
      color: ${theme.colors.text};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
    `
  );

  const Tld = styled.span(
    ({ theme }) => css`
      color: ${theme.colors.textTertiary};
      white-space: nowrap;
    `
  );

  const SubtitleWrapper = styled.div(
    ({ theme }) => css`
      font-size: ${theme.space['3.5']};
      line-height: 1.43;
      color: ${theme.colors.greyPrimary};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  );

  const ExpiryText = styled(Typography)(
    ({ theme }) => css`
      color: ${theme.colors.greyPrimary};
    `
  );

  const NoDataFound = styled.div(
    () => css`
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    `
  );

  const sortTypeOptions = [
    { label: 'Expiry Date', value: 'expiryDate' },
    { label: 'Name', value: 'name' },
    { label: 'Creation Date', value: 'createdAt' },
  ];

  const MyNamePage = () => {
    const [nameList, setNameList] = useState([]);
    const [sortTypeChange, setSortTypeChange] = useState('expiryDate');
    const [sortDirectionChange, setSortDirectionChange] = useState('asc');
    const [searchChange, setSearchChange] = useState('');
    const [walletAddress, setWalletAddress] = useState('');

    const navigate = useNavigate();

    const DOMAINS_QUERY = gql`
      query ($walletAddress: String!) {
        wrappedDomains(where: { owner: $walletAddress }) {
          domain {
            name
            createdAt
            expiryDate
          }
        }
      }
    `;

    const AvatarWrapper = ({ colors }: any) => {
      const gradient = generateRandomGradient(colors);
      // @ts-ignore
      return <AvatarLogo gradient={gradient} />;
    };

    const { data, refetch, loading } = useQuery(DOMAINS_QUERY, {
      variables: { walletAddress },
      skip: !walletAddress,
    });

    const fetchWalletAddress = async () => {
      const fetchedWalletAddress = await getWalletAddress();
      setWalletAddress(fetchedWalletAddress.toLowerCase());
      refetch();
    };

    useEffect(() => {
      fetchWalletAddress();
    }, []);

    useEffect(() => {
      if (walletAddress) {
        refetch();
      }
    }, [walletAddress]);

    useEffect(() => {
      if (data?.wrappedDomains) {
        let sortedData: any = [...data.wrappedDomains];

        sortedData.sort(
          (
            a: { domain: { [x: string]: any } },
            b: { domain: { [x: string]: any } }
          ) => {
            let aValue = a.domain[sortTypeChange];
            let bValue = b.domain[sortTypeChange];

            if (
              sortTypeChange === 'expiryDate' ||
              sortTypeChange === 'createdAt'
            ) {
              aValue = new Date(aValue * 1000);
              bValue = new Date(bValue * 1000);
            }

            if (sortDirectionChange === 'asc') {
              return aValue > bValue ? 1 : -1;
            } else {
              return aValue < bValue ? 1 : -1;
            }
          }
        );

        setNameList(sortedData);
      }
    }, [data, sortTypeChange, sortDirectionChange, walletAddress]);

    return (
      <>
        <Heading
          align="left"
          style={{ width: '100%', alignSelf: 'center', maxWidth: '48rem', color:'white' }}
        >
          Names
        </Heading>
        <div>
          <StyledCard>
            <TabWrapperWithButtons>
              <TableHeader>
                <TableHeaderLeading>
                  <TableHeaderLeadingLeft>
                    <TableHeaderLeftControlsContainer>
                      <Select
                        value={sortTypeChange}
                        size="small"
                        label="Sort by"
                        hideLabel
                        onChange={(e) => setSortTypeChange(e.target.value)}
                        options={sortTypeOptions}
                        id="sort-by"
                      />
                      <DirectionButton
                        onClick={() => setSortDirectionChange('asc')}
                      >
                        <ListDownSVG />
                      </DirectionButton>
                      <DirectionButton
                        onClick={() => setSortDirectionChange('desc')}
                      >
                        <ListUpSVG />
                      </DirectionButton>
                    </TableHeaderLeftControlsContainer>
                  </TableHeaderLeadingLeft>
                </TableHeaderLeading>
                <TableHeaderTrailing>
                  <Input
                    data-testid="name-table-header-search"
                    size="small"
                    label="search"
                    value={searchChange}
                    onChange={(e) => setSearchChange(e.target.value)}
                    hideLabel
                    icon={<MagnifyingGlassSimpleSVG />}
                    placeholder="Search"
                  />
                </TableHeaderTrailing>
              </TableHeader>
              <div data-testid="names-list">
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '50px',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner color="accent" size="small" />
                  </div>
                ) : nameList?.length > 0 ? (
                  nameList.map((name: any) => (
                    <NameItemWrapper
                      key={name?.domain?.name}
                      onClick={() => navigate(`/${name?.domain?.name}/profile`)}
                    >
                      <NameItemContainer>
                        <AvatarWrapper colors={colors} />
                        <NameItemContent>
                          <Container>
                            <Name>{name?.domain?.name?.split('.edx')[0]}.</Name>
                            <Tld>edx</Tld>
                          </Container>
                          {!containsTwoDots(name?.domain?.name) && (
                            <SubtitleWrapper>
                              <ExpiryText fontVariant="small">
                                Expires in{' '}
                                {new Date(
                                  name?.domain?.expiryDate * 1000
                                ).toLocaleDateString('en-CA')}
                              </ExpiryText>
                            </SubtitleWrapper>
                          )}
                        </NameItemContent>
                      </NameItemContainer>
                      <Tag>
                        {containsTwoDots(name?.domain?.name)
                          ? 'Manager'
                          : 'Owner'}
                      </Tag>
                    </NameItemWrapper>
                  ))
                ) : (
                  <NoDataFound>No names found for this address</NoDataFound>
                )}
              </div>
              <Footer />
            </TabWrapperWithButtons>
          </StyledCard>
        </div>
      </>
    );
  };

  export default MyNamePage;
