import { ethers } from 'ethers';
import PublicResolverAbi from './abi/PublicResolverAbi.json';
import edxRegistrarControllerAbi from './abi/edxRegistrarControllerAbi.json';
import baseRegistrarABI from './abi/baseRegistrarAbi.json';
import nameWrapperAbi from './abi/nameWrapperAbi.json';

let provider,
  signer,
  walletAddress,
  network,
  networkId,
  resolverAddress,
  edxRegistrarControllerAddress,
  nameWrapperAddress,
  baseRegistrarAddress,
  resolverContract,
  edxContract,
  baseRegistrarContract,
  nameWrapperContract;

export async function getContractInstance() {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    walletAddress = await signer.getAddress();
    network = await provider.getNetwork();
    networkId = network.chainId;

    switch (networkId) {
      case 1995:
        resolverAddress = '0xb65695Fe9729d8E775d534c4a2CB9F6A792ECD5d';
        edxRegistrarControllerAddress =
          '0x4A15579d6510DB957C3B9fEfee66A85be2980fCb';
        baseRegistrarAddress = '0x57609aD073A797C022572E06642dE3921Bef7fD0';
        nameWrapperAddress = '0x8738Ba294cC2B9d1578A890386d16d09efefC832';
        break;
      case 5424:
        resolverAddress = '0x4344E466e3B38EF4f728800dB8524170a05565B7';
        edxRegistrarControllerAddress =
          '0xc8CEebF83a7f923d2B1F1e43D04398f2b9056000';
        baseRegistrarAddress = '0x7fb64e083A9ca14B835C4e2f518b11E6A6554c0d';
        nameWrapperAddress = '0x36d6E9E9CE91729CA9C2D7c40F96734086B58e66';
        break;
      case 17000:
        resolverAddress = '0x5912C7704A356Cb18036120B7f076CfBF8390040';
        edxRegistrarControllerAddress =
          '0xD84E889d98cc6436b9945dC8Cf26505D92Dd39BE';
        baseRegistrarAddress = '0xfAB404D2d49A87Cb4351258ACa71c4C9009b3c38';
        nameWrapperAddress = '0x56CAA9b43f73DEd877c51D03e07A8e672b4EA2e9';
        break;
      default:
        // console.error('Unsupported network');
        break;
    }

    resolverContract = new ethers.Contract(
      resolverAddress,
      PublicResolverAbi,
      signer
    );
    edxContract = new ethers.Contract(
      edxRegistrarControllerAddress,
      edxRegistrarControllerAbi,
      signer
    );
    baseRegistrarContract = new ethers.Contract(
      baseRegistrarAddress,
      baseRegistrarABI,
      signer
    );

    nameWrapperContract = new ethers.Contract(
      nameWrapperAddress,
      nameWrapperAbi,
      signer
    );
  } catch (error) {}
  return {
    resolverContract,
    edxContract,
    baseRegistrarContract,
    nameWrapperContract,
  };
}

export async function getWalletAddress() {
  await getContractInstance();
  return walletAddress;
}

export async function checkConnection() {
  await getContractInstance();
  return !!walletAddress;
}

export async function getResolver() {
  await getContractInstance();
  return resolverAddress;
}

export async function getNetworkID() {
  await getContractInstance();
  return networkId;
}

export async function fetchENSAddress(search) {
  try {
    await getContractInstance();
    const node = ethers.utils.namehash(search);
    let owner = await resolverContract['addr(bytes32)'](node);
    if (owner !== ethers.constants.AddressZero) {
      return owner;
    } else {
      return ethers.constants.AddressZero;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function fetchUrl() {
  const networkId = await getNetworkID();
  if (networkId === 1995) {
    return 'https://graphql.edexa.network/subgraphs/name/edns_testnet-subgraph/';
  } else if (networkId === 5424) {
    return 'https://graphql.edexa.network/subgraphs/name/edns-subgraph/';
  } else if (networkId === 17000) {
    return 'https://graphql.edexa.network/subgraphs/name/edns_holesky-subgraph/';
  }
}

export const colors = [
  '#80deea',
  '#81d4fa',
  '#a5d6a7',
  '#ffcc80',
];

export function generateRandomGradient(colors) {
  const getRandomIndex = () => Math.floor(Math.random() * colors.length);

  let randomIndex1 = getRandomIndex();
  let randomIndex2;
  let randomIndex3;

  do {
    randomIndex2 = getRandomIndex();
  } while (randomIndex1 === randomIndex2);

  do {
    randomIndex3 = getRandomIndex();
  } while (randomIndex3 === randomIndex1 || randomIndex3 === randomIndex2);

  return `linear-gradient(45deg, ${colors[randomIndex1]} 4.54%, ${colors[randomIndex2]} 59.2%, ${colors[randomIndex3]} 148.26%)`;
}

export function containsTwoDots(str) {
  const dotCount = (str.match(/\./g) || []).length;
  return dotCount >= 2;
}

export function isMetaMaskInstalled() {
  return Boolean(window.ethereum);
}