import { Button, Input, Tag, Typography } from '@ensdomains/thorin';
import styled, { css } from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { colors, generateRandomGradient, getNetworkID } from '../common';

const SearchResultsContainer = styled.div(
  ({ theme }) => css`
    width: 28rem;
    height: min-content;
    background-color: #ffffff;
    box-shadow: 0 2px 12px ${theme.colors.border};
    border-radius: ${theme.radii.extraLarge};
    border: ${theme.borderWidths.px} ${theme.borderStyles.solid}
      ${theme.colors.border};
    &[data-error='true'] {
      border-color: ${theme.colors.red};
    }
    overflow: hidden;
    z-index: 1000;
    transform: translateY(-${theme.space['2']});
    transition: 0.35s all cubic-bezier(1, 0, 0.22, 1.6),
      0s border-color linear 0s, 0s width linear 0s;
    cursor: pointer;
  `
);

const SearchItemContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.space['2']};
    height: ${theme.space['14']};
    padding: 0 ${theme.space['4']};
    border-bottom: ${theme.borderWidths['0.375']} ${theme.borderStyles.solid}
      ${theme.colors.border};
    &:last-of-type {
      border-bottom: 0;
    }
    position: relative;
    opacity: 0.6;
  `
);

const LeadingSearchItem = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: min-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: ${theme.space['4.5']};
    flex-gap: ${theme.space['4.5']};
  `
);

const AddressAndName = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `
);

const StyledTag = styled(Tag)(
  () => css`
    width: max-content;
    justify-self: flex-end;
    overflow-wrap: normal;
    word-break: keep-all;
    white-space: nowrap;
  `
);

const AvatarLogo = styled.div(
  ({ gradient }: any) => css`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    background: ${gradient};
  `
);

const AddressTag = styled(StyledTag)(
  ({ theme }) => css`
    border: ${theme.borderWidths['0.375']} solid ${theme.colors.border};
    background-color: transparent;
  `
);

const DOMAINS_QUERY = gql`
  query {
    wrappedDomains {
      name
      owner {
        id
      }
    }
  }
`;

const LandingPage = () => {
  const [name, setName] = useState<string>('');
  const [chainId, setChainId] = useState(0);
  const navigate = useNavigate();

  async function ID() {
    const chainid = await getNetworkID();
    setChainId(chainid);
  }

  ID();

  const handleDomainNameChange = (e: { target: { value: string } }) => {
    const inputValue = e?.target?.value?.replace(/[^\w]/g, '').toLowerCase();
    setName(inputValue);
  };

  const { data } = useQuery(DOMAINS_QUERY);

  const dropdownItems: string[] = [];

  dropdownItems.push(
    data?.wrappedDomains
      .map((domain: any) => domain?.name)
      .filter((item: string) => item === `${name}.edx`)
  );

  const AvatarWrapper = ({ colors }: any) => {
    const gradient = generateRandomGradient(colors);
    // @ts-ignore
    return <AvatarLogo gradient={gradient} />;
  };

  const SearchResultsElement = (
    <SearchResultsContainer
      style={{
        width: undefined,
      }}
      data-testid="search-input-results"
    >
      {dropdownItems.map((searchItem: any, index: any) => {
        return (
          <SearchItemContainer
            key={index}
            data-testid="search-result-address"
            onClick={
              name?.length < 3
                ? undefined
                : searchItem?.[0] === undefined
                ? () => navigate(`/${name}.edx/register`)
                : () => navigate(`/${searchItem?.[index]}/profile`)
            }
            onMouseDown={(e) => e.preventDefault()}
          >
            <LeadingSearchItem>
              <AvatarWrapper colors={colors} />
              <AddressAndName>
                <Typography weight="bold">
                  {searchItem?.[0] || `${name}.edx`}
                </Typography>
              </AddressAndName>
            </LeadingSearchItem>
            <AddressTag>
              {name?.length >= 3
                ? searchItem?.[0]
                  ? 'Registered'
                  : 'Available'
                : 'Too Short'}
            </AddressTag>
          </SearchItemContainer>
        );
      })}
    </SearchResultsContainer>
  );

  return (
    <div className="main-content">
      <div className="main-text">
        <h1 style={{ fontWeight: '700' }}>Welcome to edeXa ENS</h1>
        <div className="main-sub-title">
          <div className="main-sub-title-text-white">
            Simplifying Blockchain Identity for Enterprises & <br/> Authorities
          </div>
        </div>
        <Input
          clearable
          hideLabel
          label="Domain Name"
          placeholder="Search for a name"
          size="large"
          // width="112"
          value={name}
          onChange={handleDomainNameChange}
          disabled={!chainId || chainId !== 1995}
        />
        {name && SearchResultsElement}
        <div className="main-sub-title">
          <div className="main-sub-title-text-white">
            Register, manage, and secure your blockchain identity <br/> with edeXa ENS - built for enterprises and authorities
          </div>
          <div className='learn-more-button'>
            <Button onClick={() => window.open('https://edexa.network/', '_blank')}>
              Learn more
            </Button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
